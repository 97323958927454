/* TopBar.css */

/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Roboto, sans-serif;
}

/* Top Bar Container */
.top-bar {
  width: 100%;
}

/* Top Header */
.top-head {
  background-color: #57616e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.38);
  padding: 5px 40px;
}

/* Accessibility Icon - Hidden as per original CSS */
.accessibility-icon {
  background-color: #f37021;
  padding: 10px 10px;
  display: none;
}

.accessibility-icon i {
  color: white;
  font-size: 20px;
}

/* Head Items (Careers, Locations, Schedule a Demo) */
.head-items {
  display: flex;
  gap: 20px;
  flex-grow: 1;
  justify-content: flex-end;
  margin-right: 20px;
}

.head-items .top-link {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.head-items .top-link:hover {
  color: #f37021;
}

/* Social Media Icons */
.header-social {
  gap: 12px;
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  margin-right: 5em;
}

.header-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #5fc1db;
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: background 0.3s ease;
  font-size: var(--icon-size, 12px);
  height: calc(var(--icon-size, 15px) + 2 * var(--icon-padding, 0.5em));
  width: calc(var(--icon-size, 15px) + 2 * var(--icon-padding, 0.5em));
}

.header-social a:hover {
  background-color: #4894a9;
}

/* Main Navigation */
.main-nav {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 40px;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.5);
  position: relative;
}

/* Logo */
.logo {
  margin: 2px;
  display: flex;
  position: relative !important;
}

.logo img {
  height: 50px;
}

/* Mobile Menu Toggle Button - Hidden by default */
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #57616e;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  z-index: 1000;
}

/* Desktop Navigation */
.desktop-nav {
  display: block;
}

/* Navigation Menu */
.nav-menu {
  list-style: none;
  display: flex;
  align-items: right;
  gap: 20px;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-family: Roboto, sans-serif;
}

.nav-menu p {
  position: relative;
  display: inline-block;
}

/* Style for main links */
.nav-menu p a {
  text-decoration: none;
  color: #3d4b56;
  font-size: 16px;
  padding: 10px 10px;
  display: block;
}

.nav-menu p a:hover {
  color: #f37021;
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  color: black;
  list-style: none;
  top: 100%;
  left: 0;
  width: 220px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0;
  z-index: 999; /* Ensure it appears above other elements */
}

/* Fix dropdown display issue for desktop */
.dropdown:hover > .dropdown-menu {
  display: block;
}

/* Dropdown items */
.dropdown-menu li {
  white-space: nowrap; /* Ensures text does not break */
  border-bottom: 1px solid #f0f0f0;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li a {
  color: black;
  font-size: 14px;
  display: block;
  padding: 12px;
  font-weight: 300;
}

.dropdown-menu li a:hover {
  background-color: #57616e;
  color: white;
}

/* Mobile Navigation (Below Main Nav) */
.mobile-nav {
  display: none;
  width: 100%;
  background-color: white;
  border-top: 1px solid #eee;
}

.mobile-menu-items {
  width: 100%;
}

.mobile-menu-item {
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}

.mobile-menu-item a {
  display: block;
  padding: 15px 20px;
  color: #3d4b56;
  text-decoration: none;
  font-size: 16px;
}

.mobile-menu-item.active {
  background-color: #f9f9f9;
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #57616e;
  font-size: 16px;
  padding: 15px 20px;
  cursor: pointer;
}

.mobile-submenu {
  list-style: none;
  background-color: #f7f7f7;
  padding: 0;
}

.mobile-submenu li {
  border-top: 1px solid #eee;
}

.mobile-submenu li a {
  padding: 12px 20px 12px 40px;
  font-size: 15px;
  color: #3d4b56;
}

/* Mobile Social Icons */
.mobile-social {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 20px 0;
  margin: 10px;
}

.mobile-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  text-decoration: none;
}

.mobile-social a.linkedin {
  background-color: #0077B5;
}

.mobile-social a.facebook {
  background-color: #1877F2;
}

.mobile-social a.twitter {
  background-color: #1DA1F2;
}

.mobile-social a i {
  font-size: 20px;
}

/* Active State Styling */
.mobile-menu-item.active .mobile-menu-header {
  background-color: #f7f7f7;
}

/* MOBILE RESPONSIVE STYLES */
@media (max-width: 768px) {
  /* Top header adjustments */
  .top-head {
    padding: 5px 15px;
  }
  
  .head-items {
    padding: 8px;
    margin-right: 0;
  }
  
  /* Hide desktop social icons */
  .header-social {
    display: none;
  }
  
  /* Mobile menu button */
  .mobile-menu-toggle {
    display: block;
  }
  
  /* Hide desktop menu */
  .desktop-nav {
    display: none;
  }
  
  /* Show mobile menu when toggled */
  .mobile-nav {
    display: block;
    position: absolute;
    z-index: 100;
  }
  
  /* Adjust main nav padding */
  .main-nav {
    padding: 12px 15px;
  }
  
  /* Logo adjustment */
  .logo img {
    height: 40px;
  }
  
  /* Highlight active items */
  .mobile-menu-item.active > a {
    color: #f37021;
  }
}