@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap'); 
@media all{     
  body {  
    text-align:left;
    font-family:"Roboto", Sans-serif;
    font-size:1rem;
    font-weight:400;
    line-height:1.15;
    color:#333;
  }  
} 


/* Container for the section */
.coming-soon-section, .section-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}

/* Left text section */
.coming-text {
  max-width: 100%;
  animation: slideLeft 1s ease-in-out;
}

/* Shimmer Effect for text */
.coming-text h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #000, #333, #000);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 1s infinite linear;
}

.coming-text p {
  font-size: 16px;
  color: #4b5563;
  line-height: 1.5;
}

/* Right logo section */
.coming-logo {
  max-width: 40%;
  opacity: 0;
  padding: 1em;
  animation: slideRight 1.2s ease-in-out forwards;
}

.coming-logo img {
  max-width: 80%;
  height: auto;
  transform: scale(0.9);
  animation: zoomIn 1s ease-in-out forwards;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideRight {
  from { transform: translateX(50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes zoomIn {
  from { transform: scale(0.7); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .coming-soon-section {
    flex-direction: column-reverse; /* Reverse order on mobile */
    text-align: center;
    padding: 20px;
  }
  .coming-logo {
    max-width: 40%;
    opacity: 0;
    animation: slideRight 1.2s ease-in-out forwards;
  }
  
  .coming-logo img {
    max-width: 100%;
    height: auto;
    transform: scale(0.9);
    animation: zoomIn 1s ease-in-out forwards;
  }
  .coming-text h1 {
    font-size: 22px;
  }
  
  .coming-text p {
    font-size: 12px;;
  }
}

/* Container for the section */
.green-section {
  align-items: left;
  /* justify-content: space-around; */
  background-color: #4ea72e; /* Green background */
  padding: 1em 10em;
  border: 1px solid black;
  max-width: 100%;
  min-height: 80px;
  margin-bottom: 2em;
}

/* Button styling */
.analysis-button {
  background-color: white;
  color: black;
  padding: 12px 3em;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 16px;
  /* font-weight: bold; */
  cursor: pointer;
  text-align: center;
}

/* Button hover effect */
.analysis-button:hover {
  background-color: #f0f0f0;
}

/* Right-side text */
.analysis-text {
  color: white;
  font-size: 16px;
  max-width: 500px;
  margin-left: 2em;
  line-height: 1.5;
  margin: auto
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .green-section {
    flex-direction: row;
    text-align: center;
    padding: 20px;
  }
.section-item{
  display: block;
}
  .analysis-button {
    margin-bottom: 12px;
  }

  .analysis-text {
    max-width: 100%;
  }
}

/* Footer Container */
.footer {
  background-color: white;
  width: 100%;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

/* Footer Content Layout */
.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  
}

/* Left Section (Logo & Description) */
.footer-left {
  width: 30%;
  line-height: 1.5em;
}

.footer-left img {
  width: 10em;
  margin-bottom: 1rem;
}

.footer-left p {
  font-size: 14px;
  color: #4b5563;
  margin-top: 2em;
  margin-right: 2em;
}

/* Center Section (Links) */
.footer-center {
  width: 40%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  width: 45%;
}

.footer-section h3 {
  font-weight: bold;
  /* margin-bottom: .8em; */
}

.footer-section ul {
  list-style: none;
  padding: 0;
  line-height: 1em;
}

.footer-section ul li {
  margin-bottom: 4px;
}

.footer-section ul li a {
  text-decoration: none;
  color: #374151;
  font-size: 14px;
}

.footer-section ul li a:hover {
  color: #ff8f1c;
}

/* Right Section (Policies & Social Links) */
.footer-right {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

/* Ensure social icons are also centered */
.footer-social {
  display: flex;
  gap: 12px;
}


.footer-right ul {
  list-style: none;
  padding: 0;
  margin-bottom: 12px;

}

.footer-right ul li {
  margin-bottom: 4px;
}

.footer-social {
  gap: 12px;
}

.footer-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #7fd4ed;
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: background 0.3s ease;
}

.footer-social a:hover {
  background-color: #5fc1db;
}

/* Copyright Section */
.footer-bottom {
  text-align: center;
  font-size: 14px;
  color: #6b7280;
  margin-top: 1em;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .footer-left img {
    width: 10em;
    margin-bottom: 1rem;
  }
  .footer-left {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .footer-left,
  .footer-center,
  .footer-right {
    width: 100%;
  }

  .footer-right {
    text-align: center;
  }

  .footer-social {
    justify-content: center;
  }
}
